type themingClassNames = 'color' | 'theme' | 'variant'

const useTheming = (
  color: string | null | undefined,
  className: themingClassNames = 'theme'
) => {
  if (!color) return className === 'theme' ? 'theme-default' : null
  return `${className}-${color}`
}

export default useTheming
